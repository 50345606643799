.knob.view {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.knob {
  display: flex;
  position: relative;
}
.knob .ticks {
  position: absolute;
}
.knob .ticks .tick {
  position: absolute;
  background: black;
  box-shadow: inset 0 0 0 0 black;
  width: 3px;
  transition: box-shadow 0.2s;
}
.knob .ticks .tick.active {
  box-shadow: inset 0 0 5px 2px #509eec, 0 0 0 1px #369;
}
.knob.outer {
  border-radius: 50%;
  border: 1px solid #222;
  border-bottom: 5px solid #222;
  background-image: radial-gradient(#666 6%, #333 90%);
  box-shadow: 0 5px 15px 2px black, 0 0 5px 3px black, 0 0 0 12px #444;
}
.knob.inner {
  border-radius: 50%;
}
.knob.inner .grip {
  position: absolute;
  width: 5%;
  height: 5%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background: #509eec;
  box-shadow: 0 0 3px 1px black;
}
