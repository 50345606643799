.Settings {
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  background-color: #252525;
  color: white;
}

.Small {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  color: #9ca1f1;
}
